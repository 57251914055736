import ApiManager from '../utils/apiManager'

export class DriversService {
  constructor() {
    this.apiManager = new ApiManager();
  }

  async getEvacuators() {
    const response = await this.apiManager.get({ url: '/drivers', });
    
    return response.status === 200 ? response.data: [];
  }

}
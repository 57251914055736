import React, {useState, useEffect} from 'react';
import Header from '../../components/Header/Header';
import Map from '../../components/Map';
// import Preview from '../../components/Preview';
import Footer from '../../components/Footer';
import { useMediaQuery } from 'react-responsive';
import { fetchEvacuators } from '../../thunks';

// const mock = [
//   {
//     id: 1,
//     lng: 34.0997, 
//     lat: 44.9707,
//     name: "Андрей Мясников",
//     phone: "+7 (978) 777-77-77",
//     rotationAngle: 0,
//   },
//   {
//     id: 2,
//     lng: 34.0974, 
//     lat: 44.9729,
//     name: "Андрей Мясников",
//     phone: "+7 (978) 777-77-77",
//     rotationAngle: 0,
//   },
//   {
//     id: 3,
//     lng: 34.0947, 
//     lat: 44.9708,
//     name: "Андрей Мясников",
//     phone: "+7 (978) 777-77-77",
//     rotationAngle: 0,
//   },
//   {
//     id: 4,
//     lng:  34.0850, 
//     lat: 44.9625,
//     name: "Андрей Мясников",
//     phone: "+7 (978) 777-77-77",
//     rotationAngle: 0,
//   },
// ]
// let idx = 0;

const getRotAngle = (oldCoords, newCoords) => {
  const vector = { x: newCoords.x - oldCoords.x, y: newCoords.y - oldCoords.y };
  const cos = vector.y / (Math.sqrt(vector.x**2 + vector.y**2));
  const angle = Math.acos(cos)*180/Math.PI;
  return vector.x < 0 ? -angle : angle;
}

const Main = (props) => {

  const [evacuators, setEvacuators] = useState([])
  useEffect(() => {
    let interval = setInterval(() => {
      fetchEvacuators().then(data => {
        // data=[mock[idx]];
        // idx = idx === 3 ? 0 : idx+1
        data.map(ev => {
          const oldData = evacuators.find(oldEv => oldEv.id === ev.id);
          ev.rotationAngle = oldData 
          ? getRotAngle(
            { 
              x: oldData.lng, 
              y: oldData.lat 
            }, 
            { 
              x: ev.lng, 
              y: ev.lat
            }
          ) 
          : 0;
          if (oldData?.rotationAngle && !ev.rotationAngle) {
            ev.rotationAngle = oldData.rotationAngle;
          }
          return ev;
        })
        data.sort((a,b) => a.id - b.id);
        setEvacuators(data);
      })
    }, 2000)
    return () => clearInterval(interval)
  }, [evacuators])

  const isMobile = useMediaQuery({ maxWidth: 1224 })

  return (
    <div>
      <div>
        <Header />
        {/* <Preview /> */}
        {/* <div style={{
          backgroundColor: '#393F47',
          width: '100vw',
          marginTop: '26vw',
          height: '24vw',
          position: 'absolute'
        }}></div> */}
        <Map evacuators={evacuators} />
      </div>
      {!isMobile && <div><Footer /></div>}
    </div>
  );
}

export default Main;
import L from "leaflet";
import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import RotatedMarker from './RotatedMarker'
import { useMediaQuery } from 'react-responsive';
import './Map.css';

// import axios from 'axios';

function Map({ evacuators }) {
  const [coords, setCoords] = useState([44.95, 34.11]);
  const isMobilePortrait = useMediaQuery({ maxWidth: 1224, orientation: 'portrait' })
  const isMobileLandscape = useMediaQuery({ maxWidth: 1224, orientation: 'landscape' })
  const isMobile = isMobilePortrait || isMobileLandscape

  const iconCar = L.icon({
    iconUrl: "car_icon.png",
    iconSize: [isMobilePortrait ? 100 : 40, isMobilePortrait ? 125 : 50],
    className: "marker-img",
    iconAnchor: [isMobilePortrait ? 50 : 20, isMobilePortrait ? 125 : 50]
  })

  // useEffect(() => {
  // try {
  // axios.get('https://geolocation-db.com/json/').then(response => { 
  //   setCoords([response.data.latitude, response.data.longitude])
  // })
  // } catch (error) {
  // setCoords([44.95, 34.11])
  // }
  // }, [])


  const styleMap = {
    height: isMobilePortrait ? 'calc(100vh - 150px)' : isMobileLandscape ? 'calc(100vh - 50px)' : "45.5vw",
    width: "100vw",
    marginLeft: '50%',
    transform: 'translateX(-50%)',
  }

  const styleSpan = { fontSize: isMobilePortrait ? 'xx-large' : 'inherit' }
  if (!coords.length) return;
  return (
    <MapContainer center={coords} zoom={12} zoomControl={!isMobile} style={styleMap}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {evacuators?.map((evacuator, i) =>
        <RotatedMarker
          position={[evacuator.location.lat, evacuator.location.lng]}
          icon={iconCar}
          rotationAngle={evacuator.rotationAngle}
          rotationOrigin="center"
          id={i}
          key={i}
        >
          <Popup minWidth={isMobileLandscape ? 225 : isMobilePortrait ? 550 : 0}>
            <span style={styleSpan}><strong>ФИО:</strong> {evacuator.lastName} {evacuator.firstName} {evacuator.patronymic}</span>
            <br />
            <a style={{ textDecoration: 'none' }} href={`tel:${evacuator.phone}`}><span style={styleSpan}><strong style={{ color: '#333' }}>Телефон:</strong> {evacuator.phone}</span></a>
            <br />
            <span style={styleSpan}><strong>Авто:</strong> {evacuator.vehicleInfo.brand} {evacuator.vehicleInfo.brand}</span>
            <br />
            <span style={styleSpan}><strong>Гос. номер:</strong> {evacuator.vehicleInfo.stateNumber}</span>
            <br />
            <span style={styleSpan}><strong>Грузоподъемность:</strong> {evacuator.vehicleInfo.loadCapacity} т.</span>
            <br />
            <span style={styleSpan}><strong>Манипулятор:</strong> {evacuator.vehicleInfo.hasManipulator ? 'есть' : 'отсутствует'}</span>
            <br />
            <span style={styleSpan}><strong>Сдвижная платформа:</strong> {evacuator.vehicleInfo.hasMovingPlatform ? 'есть' : 'отсутствует'}</span>
          </Popup>
        </RotatedMarker>
      )}
    </MapContainer>
  );
}

export default Map;


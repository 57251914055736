import React, {useEffect, useState} from "react";
import { useMediaQuery } from 'react-responsive';
// import { deleteAccount } from '../../thunks';

function DeleteAccount() {
  const [status, setStatus] = useState(0)

  const [credentials, setCredentials] = useState({ login: '', password: '' })


  const isMobilePortrait = useMediaQuery({ maxWidth: 1224, orientation: 'portrait' })
  const isMobileLandscape = useMediaQuery({ maxWidth: 1224, orientation: 'landscape' })

  useEffect(() => {
    if(!credentials.login || !credentials.password) return;
    const queryParams = new URLSearchParams(credentials)
    fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/login?${queryParams}`, { method: 'POST' })
    .then(res => res.json())
    .then(driver => {
      if(!driver.id) return;
      const body = JSON.stringify({ 
        adminPassword: process.env.REACT_APP_ADMIN_PASSWORD, 
        driverId: driver.id 
      })
      fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/delete`, { method: 'DELETE', body  })
      .then(res => setStatus(res.status))
      .catch(error => console.error(error));
    })
    .catch(error => console.error(error));
  }, [credentials])

  const handleSubmit = (event) => {
    event.preventDefault();
    setCredentials({
      login: event.target?.elements?.login?.value,
      password: event.target?.elements?.password?.value,
    })
  }

  const styleInput = {
    borderRadius: isMobilePortrait ? '50px' : isMobileLandscape ? '50px' :'16px',
    border: 'none',
    width: isMobilePortrait ? '500px' : isMobileLandscape ? '250px' : '15vw',
    padding: isMobilePortrait ? '40px' : isMobileLandscape ? '20px' : '1vw',
    fontSize: isMobilePortrait ? '30px' : isMobileLandscape ? '16px' :'14px',
    marginTop: isMobilePortrait ? '30px' : isMobileLandscape ? '20px' :'1vw',
  }

  const styleSubmitButton = {
    padding: isMobilePortrait ? '40px 100px' : isMobileLandscape ? '20px 40px' : '1vw 2vw',
    border: 'none',
    borderRadius: isMobilePortrait ? '50px' : isMobileLandscape ? '30px' :'16px',
    backgroundColor: 'rgb(236, 149, 30)',
    marginTop: isMobilePortrait ? '30px' : isMobileLandscape ? '20px' :'1vw',
    cursor: 'pointer',
    color: '#2F343A',
    fontSize: isMobilePortrait ? '30px' : 'inherit',
  }

  return (
    <div style={{ marginTop: isMobilePortrait ? '300px' : isMobileLandscape ? '50px'  : '10vw' }}>
      <div style={{ 
        width: isMobilePortrait ? '500px' : isMobileLandscape ? '200px'  :  '10vw',
        margin: 'auto',
      }}>
        <img src="/logo.svg" alt='OnLogist' style={{width: isMobilePortrait ? '500px' : isMobileLandscape ? '200px'  : '10vw'}} />
      </div>

      <div style={{ 
        textAlign: 'center',
        marginTop: '1vw' 
      }}>
          <span style={{ 
            color: '#EC951E',
            fontSize: isMobilePortrait ? 24 : 18
          }}>{status === 200 ? 'Ваш аккаунт успешно удалён' : [400, 401, 403, 404, 409, 500].includes(status) ? 'Ошибка при удалении аккаунта' : 'Для удаления своего аккаунта введите логин и пароль'}</span>
          <br /><br />
          {status !== 200 && 
            <form onSubmit={handleSubmit}>
                <input type="text" name="login" placeholder="Логин" style={styleInput} />
                <br />
                <input type="password" name="password" placeholder="Пароль" style={styleInput} />
                <br />
                <button type="submit" style={styleSubmitButton}>Удалить</button>
            </form>
          }
      </div>
    </div>
  );
}

export default DeleteAccount;


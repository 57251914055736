import ApiManager from '../utils/apiManager'

export class UtilsService {
  constructor() {
    this.apiManager = new ApiManager();
  }

  async verifyRegistration(driverId, code) {
    const response = await this.apiManager.get({ url: `/auth/verify/${driverId}/${code}` });
    
    return response.status;
  }

  async deleteAccount(driverId) {
    const responseDelete = await this.apiManager.delete({ 
      url: '/auth/delete',
      params: {
        adminPassword: process.env.REACT_APP_ADMIN_PASSWORD,
        driverId: driverId
      }
    });

    return responseDelete.status;
  }

}